import type { MonolithFeatureEnvDuration } from "@/modules/featureEnv/types"

import { getCookie } from "@/modules/client/cookies/getCookies"
import {
  FE_COOKIE_NAME_KRAKEND,
  FE_COOKIE_NAME_NEXT,
  FE_COOKIE_NAME_RAILS,
  FE_COOKIE_NAME_UI,
  FE_MONOLITH_DURATION_LONG,
  FE_MONOLITH_DURATION_SHORT,
} from "@/modules/featureEnv/constants"

export const getMonolithFeatureEnvInfos = (): {
  identifier: string
  duration: MonolithFeatureEnvDuration
} | null => {
  // Ex: "short-1376-jobteaser" => {idenfifier: "1376", duration: "short"}
  // Ex: "short-1376-local-jobteaser" => {identifier: "1376-local", duration: "short"}
  const value = getCookie(FE_COOKIE_NAME_RAILS)
  if (!value) return null
  const regex = new RegExp(`(${FE_MONOLITH_DURATION_SHORT}|${FE_MONOLITH_DURATION_LONG})-(\\d*(?:-.*)?)?-jobteaser`)
  const matchResult = value.match(regex)
  if (!matchResult) return null
  const [, duration, identifier] = matchResult
  return { duration: duration as MonolithFeatureEnvDuration, identifier }
}

export const getSpaFeatureEnvIdentifier = (): string | null => {
  // The feature env value from the cookie value:
  // Ex: "https://toto.cloudfront.net/ui-jobteaser/some-value/manifest.json" => "some-value"
  const value = getCookie(FE_COOKIE_NAME_UI)
  if (!value) return null
  const regex = /https.*\/ui-jobteaser\/(.*)\/manifest.json/
  const matchResult = value.match(regex)
  if (!matchResult) return null
  return matchResult[1]
}

export const getNextFeatureEnvIdentifier = (): string | null => {
  // The value of the cookie:
  // Ex: "next-value" => "next-value"
  const value = getCookie(FE_COOKIE_NAME_NEXT)
  if (!value) return null
  return value
}

export const getKrakendFeatureEnvIdentifier = (): string | null => {
  // The value of the cookie:
  // Ex: "next-value" => "next-value"
  const value = getCookie(FE_COOKIE_NAME_KRAKEND)
  if (!value) return null
  return value
}
